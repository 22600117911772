<template>
  <!-- 我的作业 -->
  <div class="quesTion">
    <class-kc-choose @isOk="isOk" />
    <div class="qustionList">
      <div>
        <!-- 作业列表 -->
        <div class="paperList" v-if="list && list.length > 0">
          <div class="item" v-for="(item, index) in list" :key="index">
            <div>
              <div class="item-title">{{ item.title }}</div>
              <div class="item-tips">共{{ item.orders ? item.orders : 0 }}题</div>
            </div>
            <div class="item-learn" @click="goLearn(item)">做作业</div>
          </div>
        </div>
      </div>
      <!-- 暂无课程 -->
      <div class="nothing" v-if="!list || list.length == 0">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <el-pagination
        class="pagination"
        :total="total"
        :current-page="pageNum"
        :page-sizes="[8, 16, 24, 40]"
        :page-size="pageSize"
        :pager-count="5"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        background
        layout="total, sizes, prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { getInfo } from "@/api/cookies";
import { AnswerClient } from "@/api/task/answerClient";
let answerClient = new AnswerClient();
import classKcChoose from "@/components/classKcChoose.vue";
export default {
  components: { classKcChoose },

  data() {
    return {
      list: [], //查询列表
      getInfo: null,
      pageSize: 8,
      pageNum: 1,
      total: 0,
      other: [
        // { fId: 4001, fName: "虚拟考场" },
        { fId: 4002, fName: "考点练习" },
      ],
    };
  },
  async created() {
    this.userInfo = getInfo();
  },
  methods: {
    /* 获取列表 */
    getList() {
      answerClient
        .myClassesWork(
          this.pageSize,
          this.pageNum,
          this.search.classId ? this.search.classId : undefined,
          this.search.kcId ? this.search.kcId : undefined,
          this.search.type ? this.search.type : undefined,
          this.userInfo ? this.userInfo.id : undefined
        )
        .then((res) => {
          this.list = res.rows;
          this.total = res.total;
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    /* --------------------搜索--------------------------------- */
    // 筛选
    isOk(val) {
      this.search = val;
      this.searchClick();
    },
    /* 搜索 */
    searchClick() {
      this.pageNum = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    goLearn(item) {
      if (!this.userInfo) {
        this.$notify({
          title: "错误",
          message: "未登录，无法做题, 请先去登录",
          type: "error",
          duration: 2000,
        });
        return;
      }
      this.$router.push(`/quesTion/examDetails?title=${item.title}&paperId=${item.sId}&examType=2`);
    },
    /* --------------------购买--------------------------------- */
  },
};
</script>
<style lang="less" scoped>
.quesTion {
  height: 100%;
}
.qustionList {
  min-height: calc(100% - 124px - 27px);
}
.zychoice {
  margin-top: 0px;
}
.qustionList {
  margin: 40px auto 20px;
  width: 1200px;
  height: calc(100% - 124px - 27px);
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(216, 216, 216, 0.7);

  overflow-y: scroll;
  padding: 10px 28px;
}
/* 试卷 试卷包 */
.paperList {
  .item {
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    .item-title {
      font-size: 15px;
      font-family: MicrosoftYaHei;
      line-height: 21px;
      color: #333333;
    }
    .item-tips {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      line-height: 12px;
      color: #999999;

      margin-top: 12px;
    }
  }
}

/* 按钮 */
.item-learn {
  width: 96px;
  height: 34px;
  background: #fcefed;

  border-radius: 2px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  line-height: 34px;
  color: #ff4027;

  text-align: center;
  cursor: pointer;
}
</style>
